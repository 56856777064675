export default {
  "Nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт"])}
  },
  "Home": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Откройте для себя мир вместе с нами!"])}
  },
  "About": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авиабилеты первого класса"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-звездочное размещение"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150 премиальных экскурсий по городу"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отобранные отели"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние модели автомобилей"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Круглосуточная служба"])}
  },
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "Services": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши услуги"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мировые туры"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование гостиниц"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путеводители"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление событиями"])}
  },
  "Destination": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место назначения"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярное направление"])}
  },
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрясающие пакеты"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование"])},
  "booking1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование онлайн"])},
  "booking2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забронировать тур"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше имя"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш адрес электронной почты"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
  "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место назначения"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальный запрос"])},
  "bookNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забронируйте сейчас"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесс"])},
  "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 простых шага"])},
  "process1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пункт назначения"])},
  "process2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить онлайн"])},
  "process3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Летайте сегодня"])},
  "travelGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путеводитель"])},
  "travelGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Познакомьтесь с нашим гидом"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["г.Наманган, улица И.Каримова, 14"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт для любого запроса"])},
  "contact1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться"])},
  "contact2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобильный"])},
  "contact3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офис"])},
  "contact4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
  "contact5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
  "about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Tamurru Travel» — туристическая компания, работающая в сфере въездного и выездного туризма. Мы организуем для путешественников туры по лучшим странам мира, а также по Узбекистану. Мы специализируемся на организации индивидуальных и групповых туров, инфотуров и ответственных туров по Узбекистану."])},
  "about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как социально ответственный туроператор, мы также организуем социальные проекты (такие как улучшение системы питьевой воды, ремонт школ и т. д.), направленные на улучшение условий жизни местного населения в районах, принимающих туристов."])},
  "home1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша главная задача сделать путешествие для туристов максимально КАЧЕСТВЕННЫМ и НЕДОРОГО"])},
  "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите желаемое направление на странице туристических пакетов."])},
  "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совершайте онлайн-оплату через платежные системы"])},
  "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лети к месту своей мечты уже сегодня"])},
  "book1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Tamurru Travel»  агентство деловых коммуникаций, на рынке организационных и туристических  услуг с 2023 года."])},
  "book2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда  компании - это профессионалы,  достигшие отличных успехов в организации мероприятий, что подтверждается репутацией квалифицированного, ответственного и профессионального организатора с креативным подходом."])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Галерея"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новостная рассылка"])},
  "summa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма к оплате"])},
  "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип карты"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
  "onlinePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн заявка"])},
  "tourismType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въездной и паломнический туризм"])},
  "service1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Визовые услуги"])},
  "service2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авиабилеты"])},
  "service3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Железнодорожные билеты"])},
  "service4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транспортные услуги"])},
  "paymentTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типы платежей"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
}