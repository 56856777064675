export default {
  "Nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "Home": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the world with us!"])}
  },
  "About": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Class Flights"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Star Accommodations"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150 Premium City Tours"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handpicked Hotels"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Model Vehicles"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 Service"])}
  },
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "Services": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WorldWide Tours"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Reservation"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Guides"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Management"])}
  },
  "Destination": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Destination"])}
  },
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awesome Packages"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOOKING"])},
  "booking1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Booking"])},
  "booking2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book A Tour"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date & Time"])},
  "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Request"])},
  "bookNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Now"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Easy Steps"])},
  "process1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose A Destination"])},
  "process2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
  "process3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fly Today"])},
  "travelGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAVEL GUIDE"])},
  "travelGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet Our Guide"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namangan city, I. Karimov str., 14"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact For Any Query"])},
  "contact1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get In Touch"])},
  "contact2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "contact3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])},
  "contact4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "contact5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Tamurru Travel\" is a travel company which works for inbound and outbound spheres of tourism. We organize tours all over the top countries of the world as well as Uzbekistan for the travellers. We are specialized to organize individual and group tours, info tours and responsible tours to Uzbekistan."])},
  "about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a socially responsible tour operator, we also organize social projects (such as drinking water system improvement, school renovation, etc) aimed at improving living conditions of the locals in areas that receive tourists."])},
  "home1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our main task is to make travel for tourists as QUALITY and CHEAP as possible"])},
  "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your preferred destination from the travel packages page"])},
  "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make online payment through payment systems"])},
  "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fly to your dream destination today"])},
  "book1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Tamurru Travel\" business communications agency, in the market of organizational and travel services since 2023."])},
  "book2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company's team consists of professionals who have achieved excellent success in organizing events, which is confirmed by the reputation of a qualified, responsible and professional organizer with a creative approach."])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
  "summa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
  "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card type"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
  "onlinePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online application"])},
  "tourismType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound and Pilgrimage tourism"])},
  "service1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa services"])},
  "service2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airline tickets"])},
  "service3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Railway tickets"])},
  "service4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport services"])},
  "paymentTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment types"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
}